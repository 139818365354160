@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.signatureContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;

  @include tablet {
    width: 100%;
    padding: 0 4px 20px 4px;
    position: relative;
    overflow-x: auto;
    overflow-y: visible;
  }

  .annotation {
    text-align: right;
    color: $lightBlue;
    user-select: none;
    font-size: 14px;

    &Hide {
      opacity: 0;
    }
  }

  .btnWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include tablet {
      position: sticky;
      bottom: 0;
      right: 0;
      left: 0;
      margin-top: 10px;
      width: 100%;
    }

    i {
      font-size: 16px;
    }

    a {
      text-decoration: none;
      color: $lightBlue;
    }

    button {
      background-color: transparent;
      border-style: none;
      color: white;
      border: 1px solid #ccc;
      display: inline-block;
      padding: 6px 12px;
      cursor: pointer;

      display: flex;
      align-items: center;
      align-self: flex-end;
      gap: 6px;
    }
  }

  .mySignature {
    width: 600px;
    height: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    position: relative;
    padding: 0 30px;
    background: $white;
    outline: 4px solid $black;

    .logo {
      position: absolute;
      right: -35px;
      top: 48%;
      width: 100px;
      transform: rotate(-90deg) translateY(-50%);
    }

    .logoVertical {
      position: absolute;
      right: 20px;
      bottom: 20px;
      width: 150px;
    }

    .signatureInfo {
      max-width: 50%;

      h1 {
        color: $lightBlue;
        font: $signatureHeading;
      }

      h2 {
        color: $black;
        font: $signatureSubtitle;
      }

      h3 {
        color: $gray;
        font: $signatureText;
      }

      .contacts {
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin-top: 15px;

        li {
          display: flex;
          gap: 5px;

          img {
            width: 16px;
          }

          p {
            font-size: 12px;
            color: $black;
          }
        }
      }
    }

    .signatureImage {
      background: $lightBlue;
      width: 500px;
      height: 350px;
      position: absolute;
      right: -120px;
      top: 0;
      transform: rotate(-60deg);
      overflow-x: hidden;
      -webkit-overflow-scrolling: none;

      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transform: rotate(60deg);
        width: 500px;
        height: 350px;
        background: rgba(124, 204, 237, 0.2);
        pointer-events: none;
      }

      div {
        position: absolute;
        width: 500px;
        height: 350px;

        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        cursor: grab;

        img {
          width: 80%;
          position: relative;
          object-fit: cover;
          transform: rotate(60deg);

          cursor: grab;

          user-drag: none;
          user-select: none;
          -moz-user-select: none;
          -webkit-user-drag: none;
          -webkit-user-select: none;
          -ms-user-select: none;
        }
      }
    }
  }
}