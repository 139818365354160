@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.formContainer {
  width: 100%;
  min-height: 100vh;
  padding: 20px 5%;
  overflow-y: auto;
  overflow-x: hidden;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  @include tablet {
    flex-direction: column;
    padding: 40px;
  }

  @include mobile {
    padding: 20px;
  }


  .form {
    width: 50%;

    @include tablet {
      width: 100%;
    }

    h3 {
      margin: 10px 0;
      color: $lightBlue;
    }

    input {
      display: block;
      width: 100%;
    }

    .contacts {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
    }

    .importImage {
      display: flex;
      align-items: flex-end;
      gap: 10px;

      @include mobile {
        flex-direction: column;
        align-items: flex-start;
      }

      input[type="file"] {
        display: none;
      }

      .upload,
      button {
        background-color: transparent;
        border-style: none;
        color: white;
        border: 1px solid #ccc;
        display: inline-block;
        padding: 6px 12px;
        cursor: pointer;

        display: flex;
        align-items: center;
        gap: 6px;

        i {
          font-size: 16px;
        }
      }
    }
  }
}