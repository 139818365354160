@mixin mobile {
  @media only screen and (max-width: 670px) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (max-width: 1024px) {
    @content;
  }
}