@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $dark;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  height: 100%;

  margin: -20px 0 0 -20px;
  padding: 0;
  z-index: 3;

  @include mobile {
    width: 100%;
    height: 100%;
  }
}