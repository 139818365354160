@import './variables.scss';

body,
html {
  background-color: $dark;
  color: $white;
  overflow-x: hidden;
  scroll-behavior: smooth;

  img {
    max-width: 100%;
  }

  h1 {
    font: $heading;
  }

  h2,
  h3 {
    font: $subtitle;
  }

  p,
  a,
  span,
  label,
  input {
    font: $text;
  }
}

input {
  &:focus {
    outline: none;
  }

  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  margin: 0;
}

::-webkit-scrollbar {
  width: 1rem;
}

::-webkit-scrollbar-track {
  background: $dark;
  border: 0.5px solid rgba($black, 0.3);
}

::-webkit-scrollbar-thumb {
  background: rgba($black, 0.3);
}

::-webkit-scrollbar-thumb:hover {
  background: rgba($black, 0.15);
}