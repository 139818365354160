@import '../../styles/variables.scss';

.input {

  .inputHeader {
    display: flex;
    align-items: flex-start;
    gap: 4px;

    label {
      font-weight: bolder;
      font-size: 20px;
    }

    a {
      font-size: 12px;
      color: $lightBlue;
    }
  }

  input {
    background-color: transparent;
    border-style: none;
    border-bottom: 1px solid white;
    padding: 10px 0;
    color: white;

    &[type="file"] {
      border-style: none;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-text-fill-color: $white;
      -webkit-box-shadow: 0 0 0px 1000px $dark inset;
      transition: background-color 5000s ease-in-out 0s;
    }

    &:focus {
      background-color: rgba($black, 0.3);
    }

  }
}