@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.jekers {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba($dark, 0.8);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  z-index: 3;

  .jekersHeader {
    background-color: $dark;
    width: 80%;
    padding: 20px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mobile {
      width: 100%;
    }

    button {
      background-color: transparent;
      border-style: none;
      color: white;
      border: 1px solid #ccc;
      display: inline-block;
      width: 36px;
      height: 36px;
      cursor: pointer;

      display: flex;
      align-items: center;
      align-self: flex-end;
      gap: 6px;

      i {
        font-size: 16px;
        width: 100%;
      }
    }
  }

  .jekersContainer {
    width: 80%;
    height: 80%;

    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 20px;

    background-color: $dark;
    padding: 20px;
    overflow-y: auto;
    position: relative;

    @include tablet {
      grid-template-columns: repeat(4, 1fr);
    }

    @include mobile {
      width: 100%;
      height: fit-content;
      grid-template-columns: repeat(3, 1fr);
    }

    .jeker {
      text-align: center;

      img {
        cursor: pointer;
      }

      p {
        margin-top: 5px;
      }

      &:first-child {
        display: none;
      }
    }
  }
}