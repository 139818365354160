@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.contentContainer {
  width: 100vw;
  height: 100vh;
  padding-bottom: 20px;
  background-color: $dark;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include tablet {
    height: initial;
  }

  .intro {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: $dark;
    z-index: 3;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      margin-left: 10%;
      filter: brightness(0) saturate(100%) invert(79%) sepia(5%) saturate(4716%) hue-rotate(167deg) brightness(101%) contrast(86%);
    }

    &Fade {
      visibility: visible;
      transition: all 1s;
      opacity: 1;
    }

    &FadeOut {
      visibility: hidden;
      transition: all 1s;
      // transform: translateY(-100%);
      opacity: 0;

      img {
        opacity: 0;
      }
    }
  }
}